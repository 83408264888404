import React from 'react';
import Layout from '../components/Layout';

const Service_one = () => {
  return (
    <Layout>
      <div id="main">
        <div className="services_section_banner">
          <div
            className="services_banner"
            style={{ backgroundImage: `url(..//images/asesoria_legal.jpeg)` }}
          >
            <h1>Servicio de Consultoría Corporativa</h1>
          </div>
        </div>
        <div className="inner">
          <div className="services_section_1">
            <h1 className="services_section_1__title">Descripción</h1>
            <h1 style={{ color: '#000000', fontSize: 20 }}>
              Servicio de Consultoría Corporativa
            </h1>

            <ul style={{ color: '#000000' }}>
              <li>Constitución de sociedades</li>
              <li>Registro de Marcas y Patentes</li>
              <li>Análisis de operaciones financieras</li>
              <li>Contratos de tecnología</li>
            </ul>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Service_one;
